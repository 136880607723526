<template>
  <div class="navbar-container d-flex content align-items-center">
    <!-- Nav Menu Toggler -->
    <ul class="nav navbar-nav d-xl-none">
      <li class="nav-item">
        <b-link class="nav-link" @click="toggleVerticalMenuActive">
          <feather-icon icon="MenuIcon" size="21" />
        </b-link>
      </li>
    </ul>

    <!-- Left Col -->
    <div class="bookmark-wrapper align-items-center flex-grow-1 d-none d-lg-flex">
      <b-navbar-nav class="nav">
        <b-nav-item v-for="(bookmark, index) in mappedBookmarks(role)" :id="`bookmark-${index}`" :key="index"
                    :to="bookmark.route">
          <feather-icon :icon="bookmark.icon" size="21" />
          <b-tooltip triggers="hover" :target="`bookmark-${index}`" :title="bookmark.title"
                     :delay="{ show: 500, hide: 50 }" />
        </b-nav-item>
      </b-navbar-nav>
    </div>

    <b-navbar-nav class="nav align-items-center ml-auto">
      <dark-Toggler class="d-lg-block" />

      <b-nav-item-dropdown v-if="user" right toggle-class="d-flex align-items-center dropdown-user-link"
                           class="dropdown-user">
        <template #button-content>
          <div class="d-sm-flex d-none user-nav">
            <p class="user-name font-weight-bolder mb-0">
              {{ user.family_name }} {{ user.given_name }}
            </p>
            <span class="user-status">{{ role.split('.')[1] }}</span>
          </div>
          <initials-avatar size="40" :family-name="user.family_name" :given-name="user.given_name"
                           class="badge-minimal" />
        </template>

        <b-dropdown-item link-class="d-flex align-items-center">
          <feather-icon size="16" icon="UserIcon" class="mr-50" />
          <span>Profil</span>
        </b-dropdown-item>

        <b-dropdown-item link-class="d-flex align-items-center" @click="logout">
          <feather-icon size="16" icon="LogOutIcon" class="mr-50" />
          <span>Déconnexion</span>
        </b-dropdown-item>
      </b-nav-item-dropdown>
    </b-navbar-nav>
  </div>
</template>

<script>
import {
  BLink, BNavbarNav, BNavItem, BNavItemDropdown, BDropdownItem, BTooltip,
} from 'bootstrap-vue'
import DarkToggler from '@core/layouts/components/app-navbar/components/DarkToggler.vue'
import InitialsAvatar from '@/components/InitialsAvatar.vue'
import { mapActions, mapGetters } from 'vuex'

export default {
  components: {
    BLink,
    BNavbarNav,
    BNavItem,
    BNavItemDropdown,
    BDropdownItem,
    BTooltip,
    DarkToggler,
    InitialsAvatar,
  },
  props: {
    toggleVerticalMenuActive: {
      type: Function,
      default: () => { },
    },
  },
  data() {
    return {
      bookmarks: [
        { title: 'Planning', route: { name: 'planning' }, icon: 'CalendarIcon' },
        { title: 'Jeunes', route: { name: 'youngs' }, icon: 'FileTextIcon' },
        { title: 'Répondeur', route: { name: 'history' }, icon: 'PhoneIcon' },
        { title: 'Blocklist', route: { name: 'blocklist' }, icon: 'MinusCircleIcon' },
        { title: 'Statistiques', route: { name: 'statistics' }, icon: 'BarChart2Icon' },
        { title: 'Utilisateurs', route: { name: 'utilisateurs' }, icon: 'UsersIcon' },
      ],
    }
  },
  computed: {
    ...mapGetters({
      user: 'oidc/oidcUser',
      role: 'users/getUserRole',
    }),
  },
  methods: {
    ...mapActions({
      logout: 'oidc/signOutOidc',
    }),
    mappedBookmarks(role) {
      const auth = {
        'LeRefuge.Administrateur': ['Statistiques', 'Utilisateurs'],
        'LeRefuge.Coordinateur': ['Planning', 'Jeunes', 'Répondeur', 'Statistiques', 'Utilisateurs', 'Blocklist'],
        'LeRefuge.Ecoutant': ['Planning', 'Jeunes', 'Répondeur', 'Blocklist'],
      }
      return this.bookmarks.filter(bookmark => auth[role]?.includes(bookmark.title))
    },
  },

}
</script>
